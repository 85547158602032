body,
html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  padding: 0;
  margin: 0;
  font-size: 14px;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.margin-auto {
  margin: 0 auto;
}

.brand__name {
  color: #fff;
  background: black;
  padding: 9px 31px;
  -webkit-box-shadow: 0px 4px 16px black;
  box-shadow: 0px 4px 16px black;
  border-radius: 24px;
  display: inline-block;
  -webkit-transform: rotate(-4.94deg);
  transform: rotate(-4.94deg);
}
.container {
  padding: 0 5%;
}
.mx-width-500 {
  max-width: 500px;
}
.bg-white {
  background-color: white;
}
.p-5 {
  padding: 500px;
}
.mt-10 {
  margin-top: 50px;
}
.bg-red {
  background-color: rebeccapurple;
}

.text-center {
  text-align: center;
}

.animated-loader {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}
.nav {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #eee;
}
.nav-items {
  list-style: none;
}

.nav-items li {
  display: inline-block;
  padding: 0px 20px;
}
.btn {
  position: relative;
  padding: 10px;
  font-size: 1rem;
  border-radius: 3px;
}

.btn__default {
  justify-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  text-decoration: none;
}

.btn__lg {
  padding: 20px 10px 20px 10px;
}

.img__screenshots {
  max-width: 305px;
  height: auto;
}

.text__align_center {
  text-align: center !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.p-5 {
  padding: 10px;
}

.mt-5 {
  margin-top: 20px;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.rounded {
  border-radius: 15px;
}

.hero-desc {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(16, 19, 38, 0.6);
  max-width: 500px;
  margin: auto;
}
.hero-title {
  margin: 0;
  font-size: 4rem;
  line-height: 58px;
  font-weight: 700 !important;
}
.grid {
  display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
  background: #fff;
}

.product-card {
  padding: 1px 0;
	transition: box-shadow, 0.4s, ease-in-out;
	border-radius: 3px;
  width: 50%;
}

.logo {
  max-width: 120px;;
}

.remove-md-xl {
  display: none;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .product-card {
    width: 30%;
  }

 
  .remove-md-xl {
    display: block;
  }
}

@media only screen and (min-width: 798px) {
  /* For desktop: */
  .product-card {
    width: 19%;
  }
}
/* 
@media only screen and (max-width: 995px) {
  .product-card {
    width: 30%;
  }
 
}
.logo {
  width: 10%;
}
@media only screen and (max-width: 425px) {
  .product-card {
    width: 30%;
  }
  .logo {
    width: 30%;
  }
  .nav-items {
    display: none;
  }
} */